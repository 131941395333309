import { useZodFormFieldSingle } from '~/components/zod/ZodForm';
import InputDate from '~/components/input/InputDay';
import { InputDateProps } from '~/components/input/InputDate';

export default function ZodFieldDate({
  name,
  placeholder,
  ...props
}: Omit<InputDateProps, 'onChange' | 'value' | 'required'> & { name: string; placeholder?: string }) {
  const [prefixed, value, setValue] = useZodFormFieldSingle<TDateISODate>(name);

  return (
    <InputDate
      {...props}
      name={prefixed}
      placeholder={placeholder}
      value={value}
      onChange={(value) => setValue(value)}
    />
  );
}
