import DropButton, { useCloseDropButton } from '~/components/interactive/DropButton';
import InputDate, { InputDateProps } from '~/components/input/InputDate';
import { useTz } from '~/components/hooks/useTz';
import IconCalendar from '~/components/icons/streamline/line/IconCalendar';

interface InputDayProps extends Omit<Extract<InputDateProps, { required: true }>, 'required'> {
  placeholder?: string;
}

function Inner({ onChange, ...props }: InputDayProps) {
  const close = useCloseDropButton();

  return (
    <InputDate
      required
      {...props}
      onChange={(v) => {
        close();

        onChange?.(v);
      }}
    />
  );
}

export default function InputDay({ placeholder = 'Select Day', ...props }: InputDayProps) {
  const tz = useTz();

  return (
    <DropButton icon={<IconCalendar />} text={props.value ? tz(props.value).toFormat('MM-dd-yyyy') : placeholder}>
      <div className="p-2">
        <Inner {...props} />
      </div>
    </DropButton>
  );
}
